<template>
  <div class="animated fadeIn">
    <b-card :border-variant="borderVariant">
      <b-card-title  v-if="header">
        <div class="row">
          <div class="col" :class="classTitle">
          {{ header }}
          </div>
        </div>
      </b-card-title>
      <b-card-text>
        <slot></slot>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
export default {
  name: 'KCard',
  props: {
    borderVariant: {
      type: String,
      default: 'light',
      required: false
    },
    header: {
      type: String,
      required: false
    },
    classTitle: {
      type: String
    },
    titleLink: {
      type: String,
      required: false
    },
    routeLink: {
      type: String,
      required: false
    }
  },
  methods: {
    goTo () {
      this.$router.push(this.routeLink)
    }
  }
}
</script>

<style scoped>
.k-card-title {
  color: rgb(158, 160, 165);
  font-size: 12px !important;
  font-weight: 500;
  /* height: 18px; */
  line-height: 18px;
  text-transform: uppercase;
}
.k-card-link {
  font-size: 12px !important;
}
</style>