var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "animated fadeIn" },
    [
      _c(
        "b-card",
        { attrs: { "border-variant": _vm.borderVariant } },
        [
          _vm.header
            ? _c("b-card-title", [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col", class: _vm.classTitle }, [
                    _vm._v(" " + _vm._s(_vm.header) + " "),
                  ]),
                ]),
              ])
            : _vm._e(),
          _c("b-card-text", [_vm._t("default")], 2),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }